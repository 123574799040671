import { ethers } from 'ethers';

import BoredApePixelClubOrdinals from "../assets/abi/BoredApePixelClubOrdinals.json";
import { ABI, SMART_CONTRACT_ADDRESS } from './constants';

export function getContract(provider:any){

    if(!provider) return null;

    const contractAddress = SMART_CONTRACT_ADDRESS;
    const abi = ABI || BoredApePixelClubOrdinals?.abi;

    let p;
    try{
        p = provider.getSigner();
    }catch(err){
        p = provider;
    }

    const bapc = new ethers.Contract(contractAddress, abi, p);

    return bapc;

}
